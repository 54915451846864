import React from "react"
import DirectionElement from "./DirectionElement"

const LineElement = ({ line, distance, max }) => {
    let lineTypeColor = undefined
    switch (line.type) {
        case "ptBusCity":
            lineTypeColor = "#1c60a7"
            break
        case "ptBusNight":
            lineTypeColor = "#1c60a7"
            break
        case "ptTram":
            // nur solange U2Z fährt notwendig
            line.lineId === 150 ? lineTypeColor = "#935e98" : lineTypeColor = "#d3312c"
            break
        case "ptTramWLB":
            lineTypeColor = "#0089C4"
            break
        case "ptMetro":
            switch (line.lineId) {
                case 301:
                    lineTypeColor = "#e20210"
                    break
                case 302:
                    lineTypeColor = "#935e98"
                    break
                case 303:
                    lineTypeColor = "#db7609"
                    break
                case 304:
                    lineTypeColor = "#319f49"
                    break
                case 305:
                    lineTypeColor = "#0089C4"
                    break
                case 306:
                    lineTypeColor = "#a4642c"
                    break
                default: lineTypeColor = "#darkgray"
                    break
            }

            break
        default:
            lineTypeColor = "darkgray"
            break
    }

    return (
        <div className={"dash-pt-lineContainer"}>
            <div className={"dash-pt-lineIconContainer"}>
                <div className={"dash-pt-lineIconGate"}>
                    {line.directionH ? line.directionH[0].gate : ""}
                </div>
                <div
                    className={"dash-pt-lineIcon"}
                    style={{ backgroundColor: lineTypeColor }}
                >
                    {line.name}
                </div>
                <div className={"dash-pt-lineIconGate"}>
                    {line.directionR ? line.directionR[0].gate : ""}
                </div>
            </div>
            <div className={"dash-pt-lineToContainer"}>
                {line.directionH && (
                    <DirectionElement
                        direction={line.directionH[0]}
                        distance={distance}
                        max={max}
                    />
                )}
                {line.directionR && (
                    <DirectionElement
                        direction={line.directionR[0]}
                        distance={distance}
                        max={max}
                    />
                )}
            </div>
        </div>
    )
}
export default LineElement
