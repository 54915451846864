import React from "react";

export const Linkbar = ({ sections }) => {
	return (
		<section className="wm-section wm-section--reduced">
			<div className="wm-section__content wm-section__content--reduced">
				<ul className="wm-h-links--noline wm-u-mbxxs wm-list--reset wm-list--pipe">
					{sections
						.filter((s) => s.visible)
						.map((s, idx) => (
							<li key={idx}>
								<a href={"#" + s.anchor}>{s.title} </a>
							</li>
						))}
				</ul>
			</div>
		</section>
	);
};
