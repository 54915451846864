import React from "react";

const DisturbancesCard = ({ traficinfo }) => {
	let sl = traficinfo.media.subscribedLines;
	let dl = traficinfo.media.disturbedLines;

	return (
		<li>
			<div className="wm-card js-wm-card" data-wm-card>
				<h3 className="wm-card__heading ">{traficinfo.title}</h3>
				<span className="wm-badge wm-card__badge">
					{traficinfo.provider}{" "}
				</span>

				<div className="wm-card__content wm-rich-text">
					{/* <p>{traficinfo.subtitle}</p> */}

					{/* 1. keine hinterlegten linien => LINK zum Profil
				2. hinterlegete linien, aber KEINE störungen => darstellung linine mit guter fahrt LINK zum Profil
				3. hinterlegte linien UND störungen => anzeigen der störungsdaten LINK zur WL Seite
				*/}

					{sl.length === 0 && (
						<>
							<span className="wm-status  wm-status">
								keine Linien ausgewählt
							</span>
							<a
								href={`${window.urlBasePath}Mein-Profil?expand=dss-account-notifications-panel`}
								className="wm-em-link  "
								data-wm-em-link
							>
								<span className="wm-em-link__content">
									Ihre Linienauswahl
								</span>
							</a>
						</>
					)}
					{dl.length !== 0 && (
						<div style={{ minHeight: "25rem" }}>
							<span className="wm-status  wm-status--progress">
								Störungen auf Ihren Linien
							</span>
							<a
								href={traficinfo.link.url}
								className="wm-card__link wm-link--unstyled "
							>
								<div style={{ margin: "2rem 0" }}>
									{dl.map((d, idx) => {
										return (
											<span key={"subscribedLine" + idx}>
												{d}
												{dl.length - 1 > idx && ","}
											</span>
										);
									})}
								</div>
								<p>Gute Fahrt!</p>
							</a>
						</div>
					)}
					{dl.length === 0 && sl.length !== 0 && (
						<div style={{ minHeight: "25rem" }}>
							<span className="wm-status  wm-status--success">
								Keine Störungen auf Ihren Linien:
							</span>
							<a
								href={traficinfo.link.url}
								className="wm-card__link wm-link--unstyled "
							>
								<div style={{ margin: "2rem 0" }}>
									{sl.map((s, idx) => {
										return (
											<span key={"subscribedLine" + idx}>
												{" "}
												{s}
												{sl.length - 1 > idx && ","}
											</span>
										);
									})}
								</div>
								<p>Gute Fahrt!</p>
							</a>
						</div>
					)}
				</div>
			</div>
		</li>
	);
};

export default DisturbancesCard;

// let profilUrl = `${window.urlBasePath}Mein-Profil?expand=dss-account-notifications-panel&channel=störungen-wiener-linien-panel`;
//<a href={profilUrl} className="wm-em-link" data-wm-em-link>
//    <span className="wm-em-link__content">Ihre Linienauswahl</span>
//</a>
