import React from "react";
import { createRoot } from "react-dom/client";

export const WebComponent = component => {
	class WC extends HTMLElement {

		static get observedAttributes() { return ['user'] }

		constructor() {
			super();
			this.root = createRoot(this);
		}

		connectedCallback() { this.update(); }
		//disconnectedCallback() { this.root.unmount(); }

		update = () => {
			let element = React.createElement(component);
			this.root.render(element);
		}

		attributeChangedCallback(name, oldValue, newValue) {
		}
	}

	return WC;
}

export const WebComponentEx = input => {
	class WC extends HTMLElement {
		static get observedAttributes() { return input.attributes }

		constructor() {
			super();
			this.root = createRoot(this);
		}

		connectedCallback() { this.update(); }
		//disconnectedCallback() { this.root.unmount(); }

		update = () => {
			var stripped = {};
			for (var i = 0; i < input.attributes.length; i++) {
				var name = input.attributes[i];
				stripped[name] = this.getAttribute(name);
			}
			stripped["sender"] = this;

			let element = React.createElement(input.component, stripped);
			this.root.render(element);
		}

		attributeChangedCallback(name, oldValue, newValue) {
			this.update();
		}
	}

	return WC;
}