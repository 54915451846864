import React from "react"
import LineElement from "./LineElement"
import iconFussg from "../../../img/patternlib/departures/fussgaengerIn.svg"
// import iconBim from "../../../img/patternlib/departures/strassenbahn_seitlich.svg"

const StationElement = ({ station, size, max }) => {
    const sortingArr = ["ptMetro", "ptTram", "ptTramWLB", "ptBusCity", "ptBusNight"]


    var lineArr = station.lines.sort(function (a, b) {
        return sortingArr.indexOf(a.type) - sortingArr.indexOf(b.type)
    })

    return (
        <>
            <div className="dash-pt-stationHeader"
            >
                <b>
                    {station.stationName}
                </b>
                <b>
                    <img
                        style={{ marginLeft: "15px" }}
                        src={iconFussg}
                        height={"18px"}
                        width={"18px"}
                        alt=""
                    />
                    {(station.distance / 50).toFixed()}&nbsp;Min.
                </b>
                {/* <div style={{ transform: "scaleX(-1)" }}>
                    <img
                        src={iconBim}
                        height={"25px"}
                        width={"25px"}
                        alt="tram"
                    />
                </div> */}
            </div>
            {station !== undefined ? (
                lineArr.map((lineItem, idx) => (
                    <LineElement
                        key={idx}
                        line={lineItem}
                        distance={station.distance / 50}
                        max={max}
                    />
                ))
            ) : (
                <>keine daten...</>
            )}
        </>
    )
}
export default StationElement
