import React from "react";

import iconAmtswege from "../../img/patternlib/static/amtswege.svg";
import iconFeder from "../../img/patternlib/static/feder.svg";
import iconInfo from "../../img/patternlib/static/information.svg";
import iconUhr from "../../img/patternlib/static/uhr.svg";
import iconPin from "../../img/patternlib/static/standortzeichen.svg";
import iconOffi from "../../img/patternlib/static/pt.svg";

export const Teaser = () => {
	return (
		<section className="wm-section">
			<div className="wm-section__content">
				<div className="wm-row">
					<div className="wm-block">
						<p>
							Erstellen Sie jetzt ein Stadt Wien Konto und nutzen Sie auf Sie
							persönlich zugeschnittene Funktionen, zum Beispiel:
						</p>
						<ul className="wm-list--reset">
							<li className="wm-u-df wm-u-aic">
								<img
									src={iconAmtswege}
									width="22"
									height="22"
									alt=""
									className="wm-u-mrxs"
								/>
								<span>Amtswege bequem online erledigen</span>
							</li>
							<li className="wm-u-df wm-u-aic">
								<img
									src={iconFeder}
									width="22"
									height="22"
									alt=""
									className="wm-u-mrxs"
								/>
								Mehr Komfort durch Datenvorbefüllung
							</li>
							<li className="wm-u-df wm-u-aic">
								<img
									src={iconInfo}
									width="22"
									height="22"
									alt=""
									className="wm-u-mrxs"
								/>
								<span>Aktuellen Status Ihrer Anträge prüfen</span>
							</li>
							<li className="wm-u-df wm-u-aic">
								<img
									src={iconUhr}
									width="22"
									height="22"
									alt=""
									className="wm-u-mrxs"
								/>
								<span>Termine buchen</span>
							</li>
							<li className="wm-u-df wm-u-aic">
								<img
									src={iconPin}
									width="22"
									height="22"
									alt=""
									className="wm-u-mrxs"
								/>
								<span>Neuigkeiten aus Ihrem Grätzl erfahren</span>
							</li>
							<li className="wm-u-df wm-u-aic">
								<img
									src={iconOffi}
									width="22"
									height="22"
									alt=""
									className="wm-u-mrxs"
								/>
								<span>Meldungen von ausgewählten Öffi-Verbindungen erhalten</span>
							</li>
						</ul>
						<p>
							<a
								href={`${window.urlBasePath}Registrieren`}
								className="wm-btn wm-u-mrs wm-u-mbs wm-btn--min"
							>
								<span>Konto Erstellen</span>
							</a>
							{/* link austauschen gegen variable */}
							<a
								id="anmelden_button"
								href={`/login?redirect=${window.location.href}`}
								className="wm-btn wm-btn--i wm-btn--min"
							>
								<span>Anmelden</span>
							</a>
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};
