import React from "react";
import PropTypes from "prop-types";
import L from "leaflet";

import markerIcon from "../../img/icons/marker-icon.png";

export const Attribution = () =>
    L.control.attribution({
        prefix:
            'Datenquelle: <a href="https://www.basemap.at/" target="_blank">basemap.at</a>',
        position: "bottomleft"
    });

export const TileLayer = () =>
    L.tileLayer(
        "https://maps{s}.wien.gv.at/basemap/bmaphidpi/normal/google3857/{z}/{y}/{x}.{format}",
        {
            format: "jpeg",
            subdomains: ["", "1", "2", "3", "4"]
        }
    );

const counter = 0;

export class MapContainer extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        style: PropTypes.object,
        className: PropTypes.string,
        mapData: PropTypes.object,
        onClick: PropTypes.func,
        onChange: PropTypes.func
    };

    static defaultProps = {
        id: "map",
        className: "",
        style: {
            width: "100%",
            height: 350,
            overflow: "hidden",
            border: "1px solid gray",
            borderRadius: "8px",
            marginBottom: 16
        },
        mapData: { zoom: 16, center: [48.210803, 16.357301] },
        onClick: (point) => { },
        onChange: (mapData) => { }
    };

    map = null;
    markLayer = null;
    id = null;

    constructor(props) {
        super(props);
        this.id = `leaflet_${props.id}_${++counter}`; 
    }

    componentDidMount() {
        L.Control.prototype._refocusOnMap = (e) => {
            if (this.map && e && e.screenX > 0 && e.screenY > 0) {
                console.log("refocus");
                this.map.getContainer().focus({ preventScroll: true });
            }
        };

        this.initMap();
    }

    initMap = () => {
        let { mapData } = this.props;

        this.map = L.map(this.id, {
            center: mapData.center,
            zoom: mapData.zoom,
            preferCanvas: true
        });

        TileLayer().addTo(this.map);
        Attribution().addTo(this.map);

        this.map.on("click", (e) => this.onClick(e));
        this.map.on("zoomend", (e) => this.onMove(e));
        this.map.on("moveend", (e) => this.onMove(e));

        this.markLayer = new L.FeatureGroup();
        this.map.addLayer(this.markLayer);

        L.marker(mapData.center, {
            icon: L.icon({
                iconUrl: markerIcon,
                iconSize: [25, 41]
            })
        }).addTo(this.map);

        this.map.setView(mapData.center, mapData.zoom);
    };

    swap = (latLng, reverse) => {
        if (reverse) {
            return latLng[0] < 40 ? latLng : [latLng[1], latLng[0]];
        } else {
            return latLng[0] < 40 ? [latLng[1], latLng[0]] : latLng;
        }
    };

    panTo = (point, mark = null) => {
        let c = this.swap(point);

        this.map.panTo(c);

        if (mark) {
            let marker = L.marker(c);
            this.markLayer.addLayer(marker);
            let popup = marker.bindPopup(`<strong>${mark}</strong>`);
            popup.openPopup();
        }
    };

    onMove = (e) =>
        this.props.onChange({
            center: this.map.getCenter(),
            zoom: this.map.getZoom()
        });

    onClick = (e) => {
        let point = this.swap([e.latlng.lat, e.latlng.lng], true);
        this.props.onClick(point);
    };

    render = () => {
        let { style, className } = this.props;

        return (
            <div
                key={this.id}
                id={this.id}
                style={style}
                className={className}
            />
        )
    };
}
