import React from "react";
import { Modal } from "react-responsive-modal";

import StationElement from "../departures/StationElement";

class DepartureCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false
		};
	}

	render = () => {
		const { id, departure } = this.props;
		const { isOpen } = this.state;

		let address = departure.address;
		let provider = "Wiener Linien";
		let subtitle = "Nähe";
		let title = "Abfahrten";

		return (
			<li>
				<div className="wm-card js-wm-card" data-wm-card>
					<h3 className="wm-card__heading ">{title}</h3>
					<span className="wm-badge wm-card__badge">{provider} </span>

					<div>
						<p>
							{address
								? subtitle + ": " + address
								: "In meiner Nähe:"}
						</p>
						<a
							href="/"
							className="wm-link--unstyled"
							onClick={(e) => {
								e.preventDefault();
								this.setState({ isOpen: true });
							}}
						>
							<div className="dash-pt-content-wrapper">
								<div className="dash-pt-content">
									{departure.stations?.length ? (
										departure.stations.map(
											(element, idx) => (
												<StationElement
													key={"stations" + idx}
													station={element}
													max={2}
												/>
											)
										)
									) : (
										<>
											Problem:{" "}
											{departure.msg ??
												" noch nicht bekannter Fehler!"}
										</>
									)}
								</div>
							</div>
						</a>
					</div>

					<Modal
						open={isOpen}
						onClose={() => this.setState({ isOpen: false })}
					>
						<div
							id={"modal-" + id}
							className="modal-dialog dash-modal-600"
						>
							<div className="modal-content">
								<div className="modal-header">
									<h3>
										{address
											? title + " " + subtitle + ": "
											: title + " in meiner Nähe:"}
									</h3>
								</div>
								<div className="modal-body">
									{address && (
										<h3 className="wm-card__heading ">
											{" "}
											{address}
										</h3>
									)}
									<div className="dash-pt-content-wrapper-modal">
										<div className="dash-pt-content-modal">
											{departure.stations?.length ? (
												departure.stations.map(
													(element, idx) => (
														<StationElement
															key={
																"station" + idx
															}
															station={element}
															max={4}
														/>
													)
												)
											) : (
												<>
													Problem:{" "}
													{departure.msg ??
														" noch nicht bekannter Fehler!"}
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal>
				</div>
			</li>
		);
	};
}
export default DepartureCard;
