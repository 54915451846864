import { useEffect, useState } from "react";
import axios from "axios";

import feedStaticData from "./feedStaticData.json";

// import mockedFeed from "./feed.mocked.json";
// import mockedDepartures from "./departures.mocked.json";

import { Teaser } from "../patterns/dashboard/Teaser";

import { Activities } from "../patterns/dashboard/Activities";
import { Termins } from "../patterns/dashboard/Termins";
import { CityMap } from "../patterns/dashboard/CityMap";
import { HereNow } from "../patterns/dashboard/HereNow";
import { FeedCard } from "../patterns/dashboard/FeedCard";

import Carousel from "../patterns/dashboard/Carousel";

export const anchor = (name) => name.replace(/[\s&?@#]*/g, "");

const FeedCarousel = ({ section }) => {
	return (
		<Carousel
			meta={{
				title: { text: section.title, url: section.anchor },
				link: section.data.fixedId === "MEINE AMTSWEGE" && {
					text: "Alle Amtswege",
					url: "/Meine-Amtswege"
				}
			}}
		>
			{section.data.items.map((card, idx) => (
				<FeedCard card={card} key={"card" + idx} />
			))}
		</Carousel>
	);
};

export const CarouselSection = (section) =>
	Object({
		title: section.name,
		anchor: anchor(section.fixedId.replace("MEINE", "")),
		data: section,
		visible: section?.items.length > 0,
		Component: FeedCarousel
	});

export const Now = (section) =>
	Object({
		title: section.name,
		anchor: anchor(section.fixedId),
		data: section,
		visible: section?.items.length > 0,
		Component: HereNow,
		departures: window.dashboardData?.departures ?? []
	});

export const Map = () =>
	Object({
		title: "Grätzl",
		anchor: "GRAETZL",
		data: [],
		visible: true,
		Component: CityMap
	});

export const BrokerSections = () => {
	let termins = window.dashboardData?.termins || [];
	let activities = window.dashboardData?.activities?.data || [];

	return [
		{
			title: "Aktivitäten",
			anchor: "AKTIVITAETEN",
			data: activities,
			visible: activities.length > 0,
			Component: Activities
		},
		{
			title: "Termine",
			anchor: "TERMINE",
			data: termins,
			visible: termins.length > 0,
			Component: Termins
		}
	];
};

export const StaticSections = () => {
	return [
		{
			title: "",
			anchor: "teaser",
			data: [],
			visible: true,
			Component: Teaser
		},
		CarouselSection(feedStaticData[0]),
		Map()
	];
};

export const getSections = (feed) => {
	if (!feed) return StaticSections();

	let brokers = BrokerSections();
	let map = Map();

	if (!feed.length) return [map, ...brokers];

	let data = [];

	feed.forEach((section) => {
		switch (section.fixedId) {
			case "JETZT":
				data.push(Now(section));
				break;

			case "GRAETZL":
				data.push(map);
				break;

			case "MEINE_AKTIVITÄTEN":
				data.push(brokers[0]);
				break;

			case "MEINE_TERMINE":
				data.push(brokers[1]);
				break;

			case "MEINE_GRUPPENAKTIVITAETEN":
				break;

			default:
				data.push(CarouselSection(section));
				break;
		}
	});

	return data;
};

export const getHome = () => {
	let adrs = window.dashboardData?.profile?.adressen?.map((a) => a.inhalt);
	let home =
		adrs?.find((a) => a.standard) ||
		adrs?.find((a) => a.title === "Zu Hause") ||
		adrs?.[0];

	return home?.coordinates || null;
};

export const useLocations = () => {
	const [location, setLocation] = useState(null);

	// geo Location
	useEffect(() => {
		let defLoc = { home: getHome(), current: null };

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(pos) => {
					setLocation({
						...defLoc,
						current: [pos.coords.longitude, pos.coords.latitude]
					});
				},
				() => {
					setLocation(defLoc);
					console.log("Failed to get your location!");
				}
			);
		} else setLocation(defLoc);
	}, []);

	return { location };
};

export const useFeedUrl = () => {
	const { location } = useLocations();
	const [url, setUrl] = useState(null);

	useEffect(() => {
		let feedUrl = window.dashboardData?.feedUrl;

		if (!location) return;

		window.dashboardData.locations = location;

		let params = "";

		if (location.home) {
			params += `&home_latitude=${location.home[1]}`;
			params += `&home_longitude=${location.home[0]}`;
		}

		if (location.current) {
			params += `&current_latitude=${location.current[1]}`;
			params += `&current_longitude=${location.current[0]}`;
		}

		if (params) feedUrl = `${feedUrl}?${params.slice(1)}`;

		setUrl(feedUrl);
	}, [location]);

	return { feedUrl: url };
};

export const useDashboardData = () => {
	const { feedUrl } = useFeedUrl();
	const [sections, setSections] = useState([]);
	const loggedin = window.dashboardData?.profile?.userName;

	// get Feed
	useEffect(() => {
		if (!feedUrl) return;

		if (!loggedin) {
			setSections(getSections(null));
			return;
		}

		let request = {
			method: "get",
			url: feedUrl,
			headers: {
				"Content-Type": "application/json"
			}
		};

		axios(request)
			.then((response) => {
				let data = response.data || [];
				window.dashboardData.feed = data;
				setSections(getSections(data));
			})
			.catch((error) => {
				setSections(getSections([]));
				console.log(error?.response);
			});
	}, [feedUrl, loggedin]);

	return { sections, loggedin };
};
