import React from "react";

import linkIcon from "../../img/icons/external.svg"
export class Activities extends React.Component {

    dateFormat = (str, format) => {
        if (!str) return "";

        let date = new Date(str);

        if (format == "day")
            return date.toLocaleString("de-AT", {
                year: 'numeric', month: '2-digit', day: '2-digit'
            });
        if (format == "hours")
            return date.toLocaleString("de-AT", {
                hour: '2-digit', minute: '2-digit'
            });

        return date.toLocaleString("de-AT", {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    safeText = (text, len = 40) => {
        if (!text) return "";
        if (text.length < len) return text;
        return text.slice(0, len) + "..."
    }

    directLink = (a) => {
        let directMA = a.type === "ManuelleAktivität" && a.inhalt?.url;
        let directBA = a.type === "BenachrichtigungAktivität"
            && a.inhalt?.url
            && !activity.inhalt?.text
            && !activity.inhalt?.htmlText;

        return (directMA || directBA);
    }

    getActivityTitel = (a) => {
        let subStyle = {
            fontSize: "0.9em",
            opacity: "0.7"
        };

        let iconStyle = {
            fontSize: "1.2em",
            color: "#999",
            marginRight: "4px",
            marginLeft: "6px"
        };

        return (
            <div className="wm-u-df" style={{ flexDirection: "column" }}>
                {a.type === "VerfahrenAktivität" &&
                    <>
                        <div>{this.safeText(a.inhalt?.name)}</div>
                        <div style={subStyle}>{a.inhalt?.statustext}</div>
                    </>
                }
                {a.type === "TerminAktivität" &&
                    <>
                        <div className="wm-u-ttt wm-u-df wm-u-aic"
                            style={{
                                flexWrap: "wrap",
                                flexDirection: "row"
                            }}>
                            {a.inhalt?.summary &&
                                <div>{this.safeText(a.inhalt.summary)}</div>
                            }
                            <div style={{
                                display: "inline-flex",
                                alignItems: "center"
                            }}>
                                <div><i aria-hidden="true" className="material-icons" style={iconStyle} >event</i></div>
                                <div>{this.dateFormat(a.inhalt?.datumVon, "day")}</div>
                                <div><i aria-hidden="true" className="material-icons" style={iconStyle} >timer</i></div>
                                <div>{this.dateFormat(a.inhalt?.datumVon, "hours")}</div>
                            </div>
                        </div>
                        <div style={subStyle}>{a.inhalt?.status === "CANCELLED" ? 'Termin storniert' : 'Termin gebucht'}</div>
                    </>
                }
                {[
                    "ManuelleAktivität",
                    "ErrungenschaftAktivität",
                    "BenachrichtigungAktivität"
                ].includes(a.type) &&
                    <>
                        <div className="wm-u-df wm-u-aic">
                            {this.directLink(a) &&
                                <img
                                    src={linkIcon}
                                    width="28"
                                    height="28"
                                    aria-hidden="true"
                                    alt="External Link"
                                    style={{ margin: "0px 4px 0px -4px" }}
                                />
                            }
                        <div>{this.safeText(a.inhalt?.titel || a.titel)}</div>
                        </div>
                        {a.inhalt?.untertitel &&
                            <div style={subStyle}>{a.inhalt.untertitel}</div>
                        }
                    </>
                }
            </div>
        );
    }

    getLink = (a) => {
        let direct = this.directLink(a);
        let url = `${window.urlBasePath}Aktivitaet-Detail?activityId=${a.id}`;

        if (direct && a.inhalt?.url) 
            url = a.inhalt.url;

        return (
            <a href={url} target="_self" className="wm-link--unstyled">
                {this.getActivityTitel(a)}
            </a>
        )
    }

    render = () => {
        let { section } = this.props;

        let len = section?.data?.length || 0;
        let max = 5;
        let mainUrl = `${window.urlBasePath}Meine-Aktivitaeten`;

        return (
            <section className="wm-section">
                <div className="wm-section__content">
                    <h2
                        id={section.anchor}
                        className="wm-anchor"
                        style={{ cursor: "pointer" }}
                    >
                        {section.title}
                    </h2>

                    <div className="wm-card js-wm-card">
                        <ul className="wm-feed">
                            {section.data.slice(0, max).map((a, ix) => (
                                <li
                                    className="wm-feed__item js-wm-block-link"
                                    data-block-link-ready="wm-feed__item--block"
                                    key={`${section.anchor}-li-${ix}`}
                                >
                                    <div className="wm-u-df--l wm-u-jcsb wm-u-aic custom-feed-item">
                                        <h3 className="wm-h5">
                                            {this.getLink(a)}
                                        </h3>
                                        <div>{this.dateFormat(a.erstelldatum, "day")}</div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {max < len && (
                        <p
                            className="wm-u-tar js-wm-block-link"
                            data-block-link-ready="wm-feed__item--block"
                        >
                            <a
                                href={mainUrl}
                                className="wm-link--unstyled"
                            >
                                Alle Aktivitäten ({len})
                            </a>
                        </p>
                    )}
                </div>
            </section>
        );
    };
}
