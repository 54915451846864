export const noServiceItem = {
    id: "wienerlinien-feed",
    link: { type: 'basicLink', url: 'https://www.wien.gv.at/gogv/l9wrlbetriebsinfo', external: false },
    media: { type: "departureTable" },
    provider: "Wiener Linien",
    subtitle: "Service nicht erreichbar!",
    title: "Abfahrten"
}

const adressUrl = `${window.urlBasePath}Mein-Profil#address-modal`;

export const noServiceAndGeoItem = {
    id: "wienerlinien-feed",
    link: { type: 'basicLink', url: adressUrl , external: false },
    media: {
        type: "markdown",
        markdown: `Jetzt eine [Adresse hinzufügen](${adressUrl}) und Abfahrtszeiten in der Nähe angezeigt bekommen.`
    },
    provider: "Wiener Linien",
    subtitle: "",
    title: "Abfahrten"
}