import React from "react";

export default Carousel = ({ meta, children }) => {
	return (
		<section className="wm-section wm-section--carousel">
			<div className="wm-section__content wm-section__content--carousel">
				{meta?.title && (
					<h2
						id={meta.title.url}
						className="wm-anchor"
						style={{ cursor: "pointer" }}
					>
						{meta.title.text}
					</h2>
				)}

				<ul className="wm-carousel js-wm-carousel">{children}</ul>

				{meta?.link && (
					<p
						className="wm-u-tar js-wm-block-link"
						data-block-link-ready="wm-feed__item--block"
					>
						<a href={meta.link.url} className="wm-link--unstyled">
							{meta.link.text}
						</a>
					</p>
				)}
			</div>
		</section>
	);
};

// nuka Carousel disabled
// import React, { Children } from "react";

// import NukaCarousel from "./NukaCarousel";

/* {meta.nuka ? (
	<NukaCarousel count={Children.count(children)}>
		{children}
	</NukaCarousel>
) : (
	<ul className="wm-carousel js-wm-carousel">{children}</ul>
)} */
