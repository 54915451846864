import React from "react";

import Carousel from "./Carousel";
import DepartureCard from "./now/DepartureCard";
import DisturbancesCard from "./now/DisturbancesCard";

import { FeedCard } from "./FeedCard";
import { noServiceAndGeoItem } from "./now/noService";

export const HereNow = ({ section }) => {
	let deps = section.departures;

	const component = (item, ix) => {
		if (item.media.type === "disturbances")
			return <DisturbancesCard key={"distCard" + ix} traficinfo={item} />;

		if (item.media.type === "departureTable") {
			if (deps.length)
				return deps.map((dep, aix) => (
					<DepartureCard
						key={"depCard" + aix}
						id={"depCard" + aix}
						departure={dep}
					/>
				));
			else
				return (
					<FeedCard
						card={noServiceAndGeoItem}
						key={"card_noservice" + ix}
					/>
				);
		}

		return <FeedCard card={item} key={"card_" + ix} />;
	};

	return (
		<Carousel
			meta={{
				title: { text: section.title, url: section.anchor }
			}}
		>
			{section.data.items.map((item, ix) => component(item, ix))}
		</Carousel>
	);
};
