import React from "react";
import Carousel from "./Carousel";

export const Termins = ({ section }) => {
	return (
		<Carousel
			meta={{
				title: { text: section.title, url: section.anchor },
				link: section?.data?.length && {
					text: `Alle Termine (${section.data.length})`,
					url: `${window.urlBasePath}Meine-Termine/`
				}
			}}
		>
			{section.data?.map((termin, idx) => (
				<Termin termin={termin} key={"card" + idx} />
			)) ?? null}
		</Carousel>
	);
};

export const Termin = ({ termin }) => {

	const dateFormat = (date) =>
		new Date(date).toLocaleString("de-DE", {
			month: "2-digit",
			day: "2-digit",
			year: "numeric"
		});

	const timeFormat = (date) =>
		new Date(date).toLocaleString("de-DE", {
			hour: "2-digit",
			minute: "2-digit"
		});

	const links = (termin) => {
		let parser = new DOMParser();
		let content = parser.parseFromString(termin.description, "text/html");
		let refs = content.getElementsByTagName("a");

		if (refs && refs.length) {
			let links = Array.from(refs);

			return (
				<>
					{links.map((l, ix) => (
						<a
							href={l.href}
							key={ix}
							className="wm-btn wm-u-w100 wm-u-mtxxs"
						>
							{l.innerText}
						</a>
					))}
				</>
			);
		}

		return null;
	};

	return (
		<li>
			<div className="wm-card js-wm-card" data-wm-card>
				<p className="wm-badge wm-card__badge">{termin.summary}</p>
				<h3 className="wm-card__heading ">
					{termin.isHtmlDescription
						? termin.summary
						: termin.description}
				</h3>

				<div className="wm-card__content wm-rich-text">
					<p>
						<span>{`Datum: ${dateFormat(termin.datumVon)}, ${timeFormat(termin.datumVon)} Uhr`}</span>
						{termin.location && (
							<>
								<br />
								<span>Ort: {termin.location}</span>
							</>
						)}
					</p>
					<div>
						<a
							href={`${window.databrokerUrl}/api/Kalender/MWDownloadTermin?id=${termin.id}`}
							className="wm-btn wm-u-w100"
						>
							in Kalender eintragen
						</a>
						{termin.isHtmlDescription && links(termin)}
					</div>
				</div>
			</div>
		</li>
	);
};
