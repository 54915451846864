import React from "react"
import realTime from "../../../img/patternlib/departures/wifi_icon.svg"

const DirectionElement = ({ direction, distance, max }) => {
    let portrait = window.matchMedia("(orientation: portrait)").matches
    const ziel0 =
        direction.departures[0] !== undefined
            ? direction.departures[0].vehicle?.towards ?? undefined
            : undefined
    const ziel1 =
        direction.departures[1] !== undefined
            ? direction.departures[1].vehicle?.towards ?? undefined
            : undefined
    const ziel = direction.towards ?? undefined
    var deptMax =
        distance !== undefined
            ? direction.departures[1] !== undefined &&
                direction.departures[1].departureTime !== undefined &&
                direction.departures[1].departureTime.countdown &&
                direction.departures[1].departureTime.countdown < distance &&
                !portrait
                ? max + 2
                : max
            : max
            if (portrait) deptMax = deptMax-1
    const Departure = ({ departurItem }) => {
        return (
            <span
                style={{
                    marginLeft: "8px",
                    textDecoration:
                        departurItem.vehicle &&
                            departurItem.vehicle.barrierFree === true
                            ? "underline"
                            : "none"
                }}
            >
                {departurItem.vehicle &&
                    departurItem.vehicle.realtimeSupported === true ? (
                    <sup>
                        <img
                            style={{ transform: "rotate(-45deg)" }}
                            src={realTime}
                            height={10}
                            width={10}
                            alt="wifi"
                        />
                        {/* <Icon fill="red" /> */}
                    </sup>
                ) : (
                    ""
                )}
                {departurItem.departureTime.countdown}
            </span>
        )
    }

    let length = direction.departures.length

    return (
        <div className={"dash-pt-lineToDir"}>
            <div
                className={"dash-pt-lineToDirText"}
                style={{ maxWidth: length > 3 ? "50%" : "75%" }}
            >
                {/* {direction.towards ?? ""} */}

                {ziel0 !== undefined && ziel1 !== undefined
                    ? ziel0.toLowerCase() === ziel1.toLowerCase()
                        ? ziel0
                        : ziel0 + " | " + ziel1
                    : ziel}

                {direction.trafficjam === true ? " ... STAU" : ""}
            </div>
            <div
                className={"dash-pt-lineToMin"}
                style={{ maxWidth: length > 3 ? "50%" : "25%" }}
            >
                {direction.departures.map((depItem, idx) => {
                    let cd =
                        depItem.departureTime.countdown !== undefined
                            ? depItem.departureTime.countdown
                            : 0

                    return (
                        <span
                            key={idx}
                            style={
                                distance !== undefined
                                    ? {
                                        fill:
                                            cd < distance
                                                ? "gray"
                                                : cd < distance + 4
                                                    ? "black"
                                                    : "black",
                                        color:
                                            cd < distance
                                                ? "gray"
                                                : cd < distance + 4
                                                    ? "black"
                                                    : "black",
                                        fontSize: "1.2rem"
                                    }
                                    : { color: "black" }
                            }
                        >
                            {idx < deptMax && (
                                <Departure departurItem={depItem} />
                            )}
                        </span>
                    )
                })}
            </div>
        </div>
    )
}

export default DirectionElement
